/* eslint-disable react/jsx-boolean-value */
/* eslint-disable react/jsx-no-undef */

import {
  Link,
  Grid,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
} from "@mui/material";

import FirstDecisionTree from "assets/cFarmPDFs/Documentation/CF_CP_decision_tree-part-1.pdf";
import FirstDecisionImg from "assets/cFarmPDFs/Documentation/CF_CP_decision_tree-part-1.png";
import SecondDecisionTree from "assets/cFarmPDFs/Documentation/CF_CP_decision_tree-part-2.pdf";
import SecondDecisionImg from "assets/cFarmPDFs/Documentation/CF_CP_decision_tree-part-2.png";
import ThirdDecisionTree from "assets/cFarmPDFs/Documentation/CF_CP_decision_tree-part-3.pdf";
import ThirdDecisionImg from "assets/cFarmPDFs/Documentation/CF_CP_decision_tree-part-3.png";

import CFBox from "components/CFBox";
import CFarmPage from "components/CFarmParts/CFarmPage";
import backgroundFarm from "assets/cFarmImages/MainPages/Home_Slide.jpg";
import CFTabView from "components/CFTabView";
import SlideShow from "components/CFSlideShow/SlideShow";
import CFTypography from "components/CFTypography";
import CFarmHomeBox from "./CFarmHomeBox";

const MethodsAndDocumentation = [
  {
    activity: <i>Cropland, Pasture, Rangeland, Orchards/Vineyards</i>,
    description: (
      <p>
        This accounting activity uses DayCent to estimate changes in
        soil-related emissions and direct nitrous oxide emissions resulting from
        management practices such as planting dates, tillage, and fertilizer
        application. Emission estimates will only relate to soil-related
        emissions and direct nitrous oxide emissions within the defined entity
        or entities (i.e. fields, pastures, vineyards, etc.).
      </p>
    ),
    chapter: (
      <Link
        href="https://www.usda.gov/oce/entity-scale-ghg-methods/chapter-3"
        target="_blank"
        underline="hover"
        rel="noopener noreferrer"
        sx={{ color: "mediumblue" }}
      >
        Chapter 3: Quantifying Greenhouse Gas Sources and Sinks in Cropland and
        Grazing Land Systems
      </Link>
    ),
  },
  {
    activity: <i>Animal Agriculture</i>,
    description: (
      <p>
        This accounting activity uses empirical calculations to estimate
        emissions resulting from livestock management practices, such as how the
        animals are housed or how manure is handled. Emission estimates will
        only relate to emissions related to livestock within the defined entity
        (i.e., heads of cattle, poultry, swine, etc.).
      </p>
    ),
    chapter: (
      <Link
        href="https://www.usda.gov/oce/entity-scale-ghg-methods/chapter-4"
        target="_blank"
        underline="hover"
        rel="noopener noreferrer"
        sx={{ color: "mediumblue" }}
      >
        Chapter 4: Quantifying Greenhouse Gas Sources and Sinks for Animal
        Production Systems
      </Link>
    ),
  },
  {
    activity: <i>Agroforestry</i>,
    description: (
      <p>
        This accounting activity uses empirical calculations to estimate
        emissions resulting from agroforestry practices such as silvopasture
        systems, riparian buffers, or windbreaks.
      </p>
    ),
    chapter: (
      <Link
        href="https://www.usda.gov/oce/entity-scale-ghg-methods/chapter-5"
        target="_blank"
        underline="hover"
        rel="noopener noreferrer"
        sx={{ color: "mediumblue" }}
      >
        Chapter 5: Quantifying Greenhouse Gas Sources and Sinks for Managed
        Forest Systems
      </Link>
    ),
  },
  {
    activity: <i>Forestry</i>,
    description: (
      <p>
        This accounting activity uses empirical calculations to estimate
        emissions resulting from forestry management practices such as clear-cut
        harvesting.
      </p>
    ),
    chapter: (
      <Link
        href="https://www.usda.gov/oce/entity-scale-ghg-methods/chapter-5"
        target="_blank"
        underline="hover"
        rel="noopener noreferrer"
        sx={{ color: "mediumblue" }}
      >
        Chapter 5: Quantifying Greenhouse Gas Sources and Sinks for Managed
        Forest Systems
      </Link>
    ),
  },
];

function MethodsDocumentationTable() {
  return (
    <TableContainer margin="1em auto">
      <Table>
        <TableHead sx={{ display: "table-header-group" }}>
          <TableRow>
            <TableCell sx={{ minWidth: 250 }}>Accounting Activity</TableCell>
            <TableCell>Description</TableCell>
            <TableCell>Blue Book Chapter</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {MethodsAndDocumentation.map((module, i) => (
            <TableRow key={`methods-documentation-row-${i.toString()}`}>
              <TableCell>{module.activity}</TableCell>
              <TableCell>{module.description}</TableCell>
              <TableCell>{module.chapter}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

const TabsAndData = {
  TabsList: [
    {
      Tab: "User Guide",
      Data: (
        <CFBox sx={{ p: 3 }}>
          <p>
            COMET-Farm<sup>TM</sup> is a web-based greenhouse gas (GHG)
            accounting decision-support tool that can be used to run a full
            greenhouse gas assessment for operations located in the contiguous
            United States. The tool is designed to do an entire greenhouse gas
            inventory within an entity, focusing on carbon dioxide (CO
            <sub>2</sub>), methane (CH<sub>4</sub>), and nitrous oxide (N
            <sub>2</sub>O) from major on-farm emission sources, in addition to
            CO<sub>2</sub> removal into biomass and carbon sinks within the
            farm-gate. Implementing the methods outlined in the U.S. Department
            of Agriculture’s (USDA) Methods for Entity-Scale Inventory, the tool
            allows users to input land management-related activity data to
            compare business-as-usual (or &quot;baseline&quot;) management to
            one or more conservation scenarios. For more information on creating
            projects, entering management, interpreting reports, and more,
            please download the{" "}
            <Link
              href={`${process.env.REACT_APP_MAIN_API_URL}COMET-Farm_Manual.pdf`}
              target="_blank"
              rel="noreferrer"
              underline="always"
              style={{
                textDecoration: "underline",
              }}
            >
              COMET-Farm Manual.
            </Link>
          </p>
          <br />
          <p>
            Due to soil and weather data limitations, COMET-Farm can only be
            used within most regions of the{" "}
            <strong>contiguous United States✵</strong>. Regions with too rocky
            or cobbly soil types are unavailable for accounting in COMET-Farm,
            and the tool is not designed to run analysis on urban agricultural
            systems. No prior training is needed to run the tool and embedded
            ‘Help’ functions are provided to assist you in running the tool.
          </p>
          <br />
          <p>
            ✵For more information about geographical limitations of the
            COMET-Tools, see the &quot;Which COMET-Tool is right for me?&quot;
            tab
          </p>
        </CFBox>
      ),
    },
    {
      Tab: "Methods & Documentation",
      Data: (
        <CFBox sx={{ p: 3 }}>
          <p>
            COMET-Farm was created in parallel with and as a function of the
            USDA&apos;s peer-reviewed{" "}
            <Link
              href="https://www.usda.gov/oce/entity-scale-ghg-methods"
              target="_blank"
              underline="hover"
              rel="noopener noreferrer"
              sx={{ color: "mediumblue" }}
            >
              Quantifying Greenhouse Gas Fluxes in Agriculture and Forestry:
              Methods for Entity-Scale Inventory
            </Link>{" "}
            (aka the &quot;Blue Book&quot;). For estimations of soil carbon and
            GHG impacts for a management activity or conservation practice to be
            included in the COMET-Tools, clearly defined methods for GHG
            accounting need to be included in the methods document.
          </p>

          <br />

          <MethodsDocumentationTable />
        </CFBox>
      ),
    },
    {
      Tab: "Information Safety",
      Data: (
        <CFBox sx={{ p: 3 }}>
          <p>
            The system uses your information on management practices together
            with spatially-explicit information on climate and soil conditions
            from USDA databases (which are provided automatically in the tool)
            to run a series of models for each potential source of greenhouse
            gas emissions.
          </p>
          <br />
          <p>
            For the Field Module, estimates are made using the DayCent dynamic
            model, which is the same model used in the official U.S. National
            Greenhouse Gas Inventory.
          </p>
          <br />
          <p>
            Emissions in the Livestock Module are estimated using statistical
            models based on USDA and university research result and are similar
            to models used in the U.S. National Inventory.
          </p>
        </CFBox>
      ),
    },
    {
      Tab: "Which COMET-Tool is right for me?",
      Data: (
        <CFBox sx={{ p: 3 }}>
          <p>
            Unsure of which COMET-Tool is most appropriate? Refer to the
            following decision trees to best select the right tool:
          </p>
          <br />
          <Grid container direction="row" justifyContent="space-between">
            <Grid item xs={4}>
              <Link
                href={FirstDecisionTree}
                target="_blank"
                underline="hover"
                px={2}
              >
                <Grid container direction="column" alignItems="center">
                  <img
                    src={FirstDecisionImg}
                    alt="COMET-Farm vs COMET-Planner"
                    height="225"
                    width="175"
                  />
                  <CFTypography
                    variant="h5"
                    fontWeight="regular"
                    pt={2}
                    px={1}
                    align="center"
                  >
                    Should I use COMET-Farm or COMET-Planner?
                  </CFTypography>
                </Grid>
              </Link>
            </Grid>
            <Grid item xs={4}>
              <Link
                href={SecondDecisionTree}
                target="_blank"
                underline="hover"
                px={2}
              >
                <Grid container direction="column" alignItems="center">
                  <img
                    src={SecondDecisionImg}
                    alt="Select Tool for Location"
                    height="225"
                    width="175"
                  />
                  <CFTypography
                    variant="h5"
                    fontWeight="regular"
                    pt={2}
                    px={1}
                    align="center"
                  >
                    Which tool is right for my location?
                  </CFTypography>
                </Grid>
              </Link>
            </Grid>
            <Grid item xs={4}>
              <Link
                href={ThirdDecisionTree}
                target="_blank"
                underline="hover"
                px={2}
              >
                <Grid container direction="column" alignItems="center">
                  <img
                    src={ThirdDecisionImg}
                    alt="COMET-Farm GUI vs API"
                    height="225"
                    width="175"
                  />
                  <CFTypography
                    variant="h5"
                    fontWeight="regular"
                    pt={2}
                    px={1}
                    align="center"
                  >
                    Should I use the COMET-Farm GUI or API?
                  </CFTypography>
                </Grid>
              </Link>
            </Grid>
          </Grid>
        </CFBox>
      ),
    },
  ],
};

function CFarmHomePage() {
  return (
    <CFarmPage title="COMET-Farm" padding={0}>
      <CFBox
        display="flex"
        justifyContent="space-around"
        sx={{
          backgroundImage: `url(${backgroundFarm})`,
          backgroundSize: "cover",
          height: "100%",
          width: "100%",
          padding: "1.5em",
          backgroundRepeat: "no-repeat",
        }}
      >
        <CFarmHomeBox />
      </CFBox>
      <CFBox
        sx={{
          width: "95%",
          m: 6,
        }}
      >
        <CFTabView>{TabsAndData}</CFTabView>
      </CFBox>

      <SlideShow />
    </CFarmPage>
  );
}

export default CFarmHomePage;
