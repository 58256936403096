// React
import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
// MUI
import { Grid } from "@mui/material";
import Stack from "@mui/material/Stack";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ContentCopyTwoToneIcon from "@mui/icons-material/ContentCopyTwoTone";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import Tooltip from "@mui/material/Tooltip";
import colors from "assets/theme/base/colors";
import CFDialog from "components/CFDialog";
import CFInput from "components/CFInput";
// Our Components
import CFBox from "components/CFBox";

import CFTypography from "components/CFTypography";
import CFButton from "components/CFButton";
import { updateLoading } from "store/reducers/mask";
import { post } from "utils/api";
import { popupError } from "utils/generic";
import {
  ADD_FEEDLOT_RATIONGROUP,
  FETCH_VALIDATE_FEEDLOT_RATION_GROUPS,
} from "constants/api";
import CFLoading from "components/CFLoading";
import FeedLotPopulation from "./FeedLotPopulation";
import FeedLotFeeds from "./FeedLotFeeds";
import FeedLotWeight from "./FeedLotWeight";
import CopyRation from "./CopyRation";
import DeleteRation from "./DeleteRation";

// Constants

export default function FeedLot({
  id,
  yearValue,
  isCopy,
  animalCategory,
  animalCategoryId,
  anagObject,
  anagObjectChanged,
  verifyDone,
}) {
  const dispatch = useDispatch();

  const { info } = colors;

  const [localPopupState, updateLocalPopup] = useState({});
  const [counterArr, setCounterArr] = useState([]);

  const [expanded, setExpanded] = useState(false);
  const [expandedSub, setExpandedSub] = useState(false);
  const [panelName, setPanelName] = useState("");
  const [subPanelName, setSubPanelName] = useState("");
  const [copyRationOpen, setCopyRationOpen] = useState(false);
  const [deleteRationOpen, setDeleteRationOpen] = useState(false);
  const [updateRations, setUpdateRations] = useState(false);
  const [rationToCopy, setRationToCopy] = useState("");
  const [rationToDelete, setRationToDelete] = useState("");
  const [loading, setLoading] = useState(false);

  const [fdlotPopChanged, setFdlotPopChanged] = useState(false);
  const [fdlotDataExists, setFdlotDataExists] = useState({
    fdlotPopExists: false,
    fdlotFeedsExists: false,
    fdlotWeightExists: false,
  });

  const handlefdlotPopChanged = (flag) => {
    setFdlotPopChanged(flag);
  };
  const handlefdlotPopCompleted = (flag) => {
    setFdlotDataExists({ ...fdlotDataExists, fdlotPopExists: flag });
  };
  const handlefdlotFeedsCompleted = (flag) => {
    setFdlotDataExists({ ...fdlotDataExists, fdlotFeedsExists: flag });
  };
  const handlefdlotWeightCompleted = (flag) => {
    setFdlotDataExists({ ...fdlotDataExists, fdlotWeightExists: flag });
  };

  const copyRationClicked = (ration) => {
    setCopyRationOpen(true);
    setUpdateRations(true);
    setRationToCopy(ration);
  };
  const deleteRationClicked = (ration) => {
    setDeleteRationOpen(true);
    setRationToDelete(ration);
  };

  const expandToggle = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
    if (newExpanded) {
      setPanelName(panel);
      setExpandedSub("");
    }
  };
  const expandToggleSub = (panel) => (event, newExpanded) => {
    setExpandedSub(newExpanded ? panel : false);
    if (newExpanded) {
      setSubPanelName(panel);
    }
  };
  const handleCopyRationClicked = (data) => {
    setCounterArr(data);

    setFdlotDataExists({
      fdlotPopExists: true,
      fdlotFeedsExists: true,
      fdlotWeightExists: true,
    });
  };

  const handleDeleteRationClicked = (data) => {
    setCounterArr(data);
  };

  const addFeedLotRtionGroup = () => {
    setLoading(true);
    const animalPopObj = {
      popid: id,
    };
    post(ADD_FEEDLOT_RATIONGROUP, animalPopObj)
      .then((res) => {
        if (res.error) {
          popupError(res.error, dispatch);
          setLoading(false);
        } else {
          const noDataRationList = JSON.parse(res.data);
          if (noDataRationList.length > 0) {
            const newRationGroupName = noDataRationList[0];
            setCounterArr([...counterArr, newRationGroupName]);
            verifyDone("grey");
          } else {
            popupError("Cannot add more than 5 Ration Groups", dispatch);
          }
        }

        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const fetchAllFeedLotRationGroups = () => {
    setLoading(true);
    const popObj = {
      popid: id,
    };
    post(FETCH_VALIDATE_FEEDLOT_RATION_GROUPS, popObj)
      .then((res) => {
        if (res.error) {
          popupError(res.error, dispatch);
          setLoading(false);
        } else {
          const ration = res.data;

          setCounterArr(ration.rationGroupNames);
          if (ration.feedLotDataComplete) {
            verifyDone("green");
          } else {
            verifyDone("grey");
          }
          // setCounterArr((oldCounterArr) => [...oldCounterArr, ...rationList]);
        }

        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  useEffect(() => {
    if (updateRations) {
      setUpdateRations(false);
      fetchAllFeedLotRationGroups();
    }
  }, [updateRations]);
  useEffect(() => {
    fetchAllFeedLotRationGroups();
  }, []);

  useEffect(() => {
    if (
      fdlotDataExists.fdlotPopExists &&
      fdlotDataExists.fdlotFeedsExists &&
      fdlotDataExists.fdlotWeightExists
    ) {
      verifyDone("green");
    } else {
      verifyDone("grey");
    }
  }, [
    fdlotDataExists.fdlotPopExists,
    fdlotDataExists.fdlotFeedsExists,
    fdlotDataExists.fdlotWeightExists,
  ]);

  return (
    <CFBox width="100%">
      {loading && <CFLoading local />}
      <Grid container item>
        {/* Main form */}
        <CFBox width="100%">
          <Grid container direction="row" xs={12} sm={12} md={12}>
            <Grid container py={2} px={2} justifyContent="space-around">
              <Grid item xs={12} sm={12} md={12}>
                <CFBox>
                  <CFTypography
                    sx={{
                      fontSize: "0.8em",

                      color: info.dullFocus,

                      marginBottom: "0.7em",
                    }}
                  >
                    Click Ration Group 1 to enter details for the ration. Up to
                    5 ration groups may be entered for each feedlot by clicking{" "}
                    <i>+Add Ration Group</i>.
                  </CFTypography>
                </CFBox>
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                {counterArr.map((counter) => (
                  <CFBox width="100%" marginBottom=".5em">
                    <Accordion
                      expanded={expanded === counter}
                      onChange={expandToggle(counter)}
                    >
                      <AccordionSummary
                        expandIcon={
                          <ExpandMoreIcon sx={{ color: colors.text.light }} />
                        }
                        aria-controls="animalDetailsCard-content"
                        id="animalDetailsCard-header"
                        sx={{
                          backgroundColor: colors.highlight.main,
                          borderStyle: "outset",
                          borderRadius: ".3em",
                          flexDirection: "row-reverse",
                        }}
                      >
                        <Grid
                          item
                          container
                          alignItems="center"
                          justifyContent="flex-start"
                        >
                          {/* Year */}

                          <CFBox
                            xs={12}
                            sx={{
                              textAlign: "left",
                              marginLeft: "1em",
                              marginRight: "1em",
                              fontSize: "medium",
                              fontWeight: "550",
                              color: colors.text.light,
                            }}
                          >
                            {counter}
                          </CFBox>
                        </Grid>
                      </AccordionSummary>
                      {/* TODO: Set up accessibility features for drop-down actions */}
                      <AccordionDetails>
                        <FeedLotPopulation
                          fdlotid={id}
                          fdlotherdName={counter}
                          fdlotyearValue={yearValue}
                          fdlotcopy={isCopy}
                          fdlotanimalCategory={animalCategory}
                          fldlotRation={panelName}
                          fdlotExpanded={expanded}
                          fdlotanimalCategoryId={animalCategoryId}
                          fdlotanagObject={anagObject}
                          fdlotanagObjectChanged={anagObjectChanged}
                          fdlotPopComplete={handlefdlotPopCompleted}
                          fdlotPopEdited={handlefdlotPopChanged}
                          expanded={expandedSub}
                          expandToggle={expandToggleSub}
                        />
                        <FeedLotFeeds
                          fdlotid={id}
                          fdlotherdName={counter}
                          fdlotyearValue={yearValue}
                          fldlotRation={panelName}
                          fdlotExpanded={expanded}
                          fdlotanimalCategory={animalCategory}
                          fdlotanimalCategoryId={animalCategoryId}
                          fdlotanagObject={anagObject}
                          fdlotanagObjectChanged={anagObjectChanged}
                          fdlotFeedsComplete={handlefdlotFeedsCompleted}
                          expanded={expandedSub}
                          expandToggle={expandToggleSub}
                        />
                        <FeedLotWeight
                          fdlotid={id}
                          fdlotherdName={counter}
                          fdlotyearValue={yearValue}
                          fldlotRation={panelName}
                          fdlotExpanded={expanded}
                          fdlotanimalCategory={animalCategory}
                          fdlotanimalCategoryId={animalCategoryId}
                          fdlotanagObject={anagObject}
                          fdlotanagObjectChanged={anagObjectChanged}
                          fdlotWeightComplete={handlefdlotWeightCompleted}
                          fdlotPopFlag={fdlotPopChanged}
                          expanded={expandedSub}
                          expandToggle={expandToggleSub}
                        />
                        <CFBox style={{ display: "flex", marginTop: "4em" }}>
                          {counterArr.length > 1 && (
                            <Tooltip title="Copy to other Ration Groups">
                              <ContentCopyTwoToneIcon
                                onClick={() => copyRationClicked(counter)}
                                id={`copyRation${counter}`}
                                color="primary"
                                fontSize="medium"
                                style={{
                                  cursor: "pointer",

                                  marginLeft: "1em",
                                }}
                              />
                            </Tooltip>
                          )}
                          {counter !== "Ration Group 1" && (
                            <Tooltip title="Delete Ration Group">
                              <DeleteForeverIcon
                                onClick={() => deleteRationClicked(counter)}
                                id={`deleteRation${counter}`}
                                color="primary"
                                fontSize="medium"
                                style={{
                                  cursor: "pointer",

                                  marginLeft: "2em",
                                }}
                              />
                            </Tooltip>
                          )}
                        </CFBox>
                        <CFBox style={{ display: "flex" }}>
                          {counterArr.length > 1 && (
                            <CFBox
                              onClick={() => copyRationClicked(counter)}
                              sx={{ textAlign: "left" }}
                              style={{
                                cursor: false ? "pointer" : "",
                                fontSize: "medium", // disabled because copy is not enabled on backend
                                color: info.dullFocus,
                                marginLeft: "1em",
                              }}
                            >
                              Copy
                            </CFBox>
                          )}

                          {counter !== "Ration Group 1" && (
                            <CFBox
                              onClick={() => deleteRationClicked(counter)}
                              sx={{ textAlign: "left" }}
                              style={{
                                cursor: false ? "pointer" : "",
                                fontSize: "medium", // disabled because copy is not enabled on backend
                                color: info.dullFocus,
                                marginLeft: "2em",
                              }}
                            >
                              Delete
                            </CFBox>
                          )}
                        </CFBox>
                      </AccordionDetails>
                    </Accordion>
                  </CFBox>
                ))}
              </Grid>
              <CopyRation
                isRationOpen={copyRationOpen}
                setIsRationOpen={setCopyRationOpen}
                // fetchAllProjects={fetchAllProjects}
                fdlotherdName={rationToCopy}
                fdlotid={id}
                fdlotyearValue={yearValue}
                fdlotanimalCategory={animalCategory}
                fdlotanimalCategoryId={animalCategoryId}
                fdlotanagobject={anagObject}
                fdlotanagobjectChanged={anagObjectChanged}
                allRations={counterArr}
                copyRationClicked={handleCopyRationClicked}
              />
              <DeleteRation
                isRationOpen={deleteRationOpen}
                setIsRationOpen={setDeleteRationOpen}
                // fetchAllProjects={fetchAllProjects}
                fdlotherdName={rationToDelete}
                fdlotid={id}
                fdlotyearValue={yearValue}
                fdlotanimalCategory={animalCategory}
                fdlotanimalCategoryId={animalCategoryId}
                fdlotanagobject={anagObject}
                fdlotanagobjectChanged={anagObjectChanged}
                deleteRationClicked={handleDeleteRationClicked}
              />
            </Grid>
            <Grid container py={2} px={2} justifyContent="space-around">
              <Grid item xs={12} sm={12} md={12} />
            </Grid>

            <Grid container py={2} px={2} justifyContent="space-around">
              <Grid item xs={12} sm={12} md={12}>
                <CFButton
                  type="submit"
                  sx={{
                    backgroundColor: colors.info.dullFocus,
                    color: "white.main",
                    float: "right",
                  }}
                  onClick={() => addFeedLotRtionGroup()}
                  // disabled={showNameError}
                >
                  + Add Ration Group
                </CFButton>
              </Grid>
            </Grid>
          </Grid>
        </CFBox>
        {localPopupState && localPopupState.showPopup && (
          <CFDialog
            {...localPopupState}
            updatePopup={(e) => updateLocalPopup(e)}
          />
        )}
      </Grid>
    </CFBox>
  );
}
