import { Grid, Link } from "@mui/material";
import {
  LibraryBooks,
  MenuBook,
  YouTube,
  HelpOutline,
  ContactMail,
  Event,
  Newspaper,
} from "@mui/icons-material";

import CFarmPage from "components/CFarmParts/CFarmPage";
import CFBox from "components/CFBox";
import CFTypography from "components/CFTypography";
import CometFarmBrochure from "assets/cFarmPDFs/Brochures/CometFarmBrochure.pdf";
import CFarmPreview from "assets/cFarmPDFs/Brochures/CometFarmBrochure.png";
import CometPlannerBrochure from "assets/cFarmPDFs/Brochures/CometPlannerBrochure.pdf";
import CPlannerPreview from "assets/cFarmPDFs/Brochures/CometPlannerBrochure.png";
import backgroundBarn from "assets/cFarmImages/MainPages/corner_barn.png";

// Embedded YouTube video component
function YouTubeEmbed({ embedId }) {
  return (
    <CFBox>
      <iframe
        title="Embedded Player"
        src={`https://www.youtube.com/embed/${embedId}`}
        height="315"
        width="560"
        allowFullScreen
        style={{ borderRadius: ".5em" }}
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
      />
    </CFBox>
  );
}

function Documentation() {
  return (
    <CFarmPage>
      <CFBox
        pt="3%"
        pb="5%"
        sx={{
          width: "90%",
          backgroundImage: `url(${backgroundBarn})`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "left center",
        }}
      >
        <Grid container direction="row">
          {/* Icons and Links */}
          <Grid
            item
            xs={7}
            height="35em"
            container
            direction="column"
            justifyContent="space-between"
            alignItems="start"
          >
            {/* Title */}
            <Grid item container direction="row" alignItems="center">
              <CFTypography variant="h2" sx={{ color: "primary.main" }}>
                Documentation
              </CFTypography>
            </Grid>
            {/* Blue Book */}
            <Grid item container direction="row" alignItems="center" pl={3}>
              <LibraryBooks sx={{ scale: "1.75" }} />
              <Link
                underline="hover"
                target="_blank"
                rel="noreferrer"
                href="https://www.usda.gov/sites/default/files/documents/USDA-Methods-Report-2024.pdf"
              >
                <CFTypography variant="h5" fontWeight="regular" pl={3}>
                  USDA Entity Scale Methods Document / &quot;Blue Book&quot;
                </CFTypography>
              </Link>
            </Grid>
            {/* User Guide */}
            <Grid item container direction="row" alignItems="center" pl={3}>
              <MenuBook sx={{ scale: "1.9" }} />
              <Link
                href={`${process.env.REACT_APP_MAIN_API_URL}COMET-Farm_Manual.pdf`}
                target="_blank"
                rel="noreferrer"
                underline="hover"
              >
                <CFTypography variant="h5" fontWeight="regular" pl={3}>
                  COMET-Tools User Guide
                </CFTypography>
              </Link>
            </Grid>
            {/* Training Vids */}
            <Link
              href="https://www.youtube.com/@comet-tools/videos"
              target="_blank"
              underline="hover"
              pl={3}
            >
              <Grid
                container
                direction="row"
                alignItems="center"
                justifyContent="start"
              >
                <YouTube sx={{ scale: "1.9" }} />
                <CFTypography variant="h5" fontWeight="regular" pl={3}>
                  COMET-Farm Training Videos
                </CFTypography>
              </Grid>
            </Link>
            {/* FAQ */}
            <Link
              href="https://cometfarm.freshdesk.com/support/home"
              target="_blank"
              underline="hover"
              pl={3}
            >
              <Grid
                container
                direction="row"
                alignItems="center"
                justifyContent="start"
              >
                <HelpOutline sx={{ scale: "1.75" }} />
                <CFTypography variant="h5" fontWeight="regular" pl={3}>
                  Frequently Asked Questions
                </CFTypography>
              </Grid>
            </Link>
            {/* Help Desk */}
            <Link
              href="https://cometfarm.freshdesk.com/support/tickets/new"
              target="_blank"
              underline="hover"
              pl={3}
            >
              <Grid
                container
                direction="row"
                alignItems="center"
                justifyContent="start"
              >
                <ContactMail sx={{ scale: "1.75" }} />
                <CFTypography variant="h5" fontWeight="regular" pl={3}>
                  Help Desk / Submit a Ticket
                </CFTypography>
              </Grid>
            </Link>
            {/* Training Calendar */}
            <Link href="/training" underline="hover" pl={3}>
              <Grid
                container
                direction="row"
                alignItems="center"
                justifyContent="start"
              >
                <Event sx={{ scale: "1.75" }} />
                <CFTypography variant="h5" fontWeight="regular" pl={3}>
                  Training Calendar
                </CFTypography>
              </Grid>
            </Link>
            {/* Newsletter */}
            <Link
              href="https://cometfarm.freshdesk.com/support/solutions/folders/64000166401"
              target="_blank"
              underline="hover"
              pl={3}
            >
              <Grid
                container
                direction="row"
                alignItems="center"
                justifyContent="start"
              >
                <Newspaper sx={{ scale: "1.75" }} />
                <CFTypography variant="h5" fontWeight="regular" pl={3}>
                  COMET Quarterly Newsletter
                </CFTypography>
              </Grid>
            </Link>
          </Grid>
          {/* Videos and PDFs */}
          <Grid
            item
            xs={5}
            container
            direction="row"
            alignItems="center"
            justifyContent="space-evenly"
            pt={6}
          >
            {/* Videos */}
            <Grid item container direction="column" alignItems="center">
              <YouTubeEmbed embedId="mq1q-Uc7UIo?si=RA0cB1GU1NEVE_pV" />
              <CFTypography variant="h6" fontWeight="regular">
                COMET-Farm and COMET-Planner Overview (New version coming soon!)
              </CFTypography>
            </Grid>
            <Grid item container direction="column" alignItems="center" pt={4}>
              <YouTubeEmbed embedId="sC63SJJ3LRM?si=b8Vsovk6FvYqYmMd" />
              <CFTypography variant="h6" fontWeight="regular">
                Introduction to COMET-Farm (New version coming soon!)
              </CFTypography>
            </Grid>
          </Grid>{" "}
          {/* PDFs */}
          <Grid
            item
            container
            direction="row"
            alignItems="center"
            justifyContent="space-evenly"
            pt={8}
            pb={4}
          >
            <Link href={CometFarmBrochure} target="_blank" underline="hover">
              <Grid container direction="column" alignItems="center">
                <img
                  src={CFarmPreview}
                  alt="COMET-Farm Brochure"
                  height="225"
                  width="175"
                />
                <CFTypography variant="h6" fontWeight="regular" pt={2}>
                  COMET-Farm PDF Overview (New version coming soon!)
                </CFTypography>
              </Grid>
            </Link>
            <Link href={CometPlannerBrochure} target="_blank" underline="hover">
              <Grid container direction="column" alignItems="center">
                <img
                  src={CPlannerPreview}
                  alt="COMET-Planner Brochure"
                  height="225"
                  width="175"
                />
                <CFTypography variant="h6" fontWeight="regular" pt={2}>
                  COMET-Planner PDF Overview (New version coming soon!)
                </CFTypography>
              </Grid>
            </Link>
          </Grid>
        </Grid>
      </CFBox>
    </CFarmPage>
  );
}

export default Documentation;
