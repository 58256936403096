// MUI
import { Grid, RadioGroup, Radio, FormControlLabel } from "@mui/material";

// React
import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect } from "react";

// CFarm Components
import CFButton from "components/CFButton";
import CFTypography from "components/CFTypography";
import CFTabView from "components/CFTabView";
import CFDialog from "components/CFDialog";
import { updateLoading } from "store/reducers/mask";

// Post
import { post } from "utils/api";
import { RUN_ANIMAL_REPORTS } from "constants/api";

// Local Components
import { isDemo } from "utils/generic";
import AnimalAgTable from "./AnimalAgTable";
import AnimalAgGraph from "./AnimalAgGraph";
import AnimalAgScenarioSelect from "./AnimalAgScenarioSelect";
import AnimalAgProjectGraph from "./AnimalAgProjectGraph";

function AnimalAgReport({ reportInfo, setReport }) {
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.user);
  const [localPopupState, updateLocalPopup] = useState({});

  const [animalAgricultureReport, setAnimalAgricultureReport] = useState([]);
  const fetchAnimalAgReport = () => {
    dispatch(updateLoading({ loading: true }));

    const animalOpObj = {
      id: currentUser,
    };
    post(RUN_ANIMAL_REPORTS, animalOpObj)
      .then((res) => {
        if (res.error) {
          updateLocalPopup({
            message: res.error,
            title: "Error",
            showPopup: true,
            closeAction: () => {
              updateLocalPopup({
                showPopup: false,
              });
            },
          });
          dispatch(updateLoading({ loading: false }));
        } else {
          try {
            const animalObject = JSON.parse(res.data);
            console.log("this is the report", animalObject);
            setAnimalAgricultureReport(animalObject);
          } catch (e) {
            updateLocalPopup({
              message: e,
              title: "Error",
              showPopup: true,
              closeAction: () => {
                updateLocalPopup({
                  showPopup: false,
                });
              },
            });
          }
        }

        dispatch(updateLoading({ loading: false }));
      })
      .catch((err) => {
        updateLocalPopup({
          message:
            "Animal Agriculture Operation does not Exist. Please select Animal Agriculture module in the Choose Activities page",
          title: "Error",
          showPopup: true,

          closable: false,
          button1: {
            text: "Navigate to Choose Activities",

            href: "/projects",
            color: "primary",
          },
        });
        dispatch(updateLoading({ loading: false }));
      });
  };
  useEffect(() => {
    if (reportInfo && reportInfo.length > 0) {
      setAnimalAgricultureReport(reportInfo);
    } else fetchAnimalAgReport();
  }, []);

  // Overall project total emissions
  const ComputeProjectTotals = () => {
    if (animalAgricultureReport && animalAgricultureReport.length > 0) {
      let tempTotals = [];

      // Create totals object for baseline, and one for each scenario's emissions and change
      const baselineEmissions = {
        field: "baselineEmissions",
        value: 0,
      };
      tempTotals.push(baselineEmissions);

      animalAgricultureReport[0].scenarios.forEach((scenario) => {
        const scenarioEmissions = {
          field: `${scenario.name}/ScenarioEmissions`,
          value: 0,
        };
        const scenarioChange = {
          field: `${scenario.name}/ScenarioEmissionsChange`,
          value: 0,
        };

        tempTotals.push(scenarioEmissions);
        tempTotals.push(scenarioChange);
      });

      // Iterate through each species group, pull baseline data and data for each scenario
      animalAgricultureReport.forEach((group) => {
        // Baseline
        tempTotals = tempTotals.map((totalObj) => {
          if (totalObj.field === "baselineEmissions") {
            let newTotal = totalObj.value;
            group.baselineScenario.emissionsCategories.forEach((category) => {
              newTotal += category.value;
            });
            return { ...totalObj, value: newTotal };
          }
          return totalObj;
        });

        // Scenarios
        group.scenarios.forEach((scenario) => {
          tempTotals = tempTotals.map((totalObj) => {
            // Emissions
            if (totalObj.field === `${scenario.name}/ScenarioEmissions`) {
              let newTotal = totalObj.value;
              scenario.emissionsCategories.forEach((category) => {
                newTotal += category.value;
              });
              return { ...totalObj, value: newTotal };
            }
            // Change
            if (totalObj.field === `${scenario.name}/ScenarioEmissionsChange`) {
              let newTotal = totalObj.value;
              group.baselineScenario.emissionsCategories.forEach(
                (baselineCategory) => {
                  // has possibly less
                  scenario.emissionsCategories.forEach(
                    // baseline has all properties
                    (scenarioCategory) => {
                      if (baselineCategory.name === scenarioCategory.name) {
                        newTotal +=
                          scenarioCategory.value - baselineCategory.value;
                      }
                    }
                  );
                }
              );
              return { ...totalObj, value: newTotal };
            }
            return totalObj;
          });
        });
      });

      return tempTotals;
    }

    return [];
  };
  const [projectTotals, setProjectTotals] = useState(ComputeProjectTotals());

  // Future scenario display
  const [futureScenarios, setFutureScenarios] = useState(
    animalAgricultureReport[0]?.scenarios
  );
  const [displayedScenarios, setDisplayedScenarios] = useState([]);
  const [scenarioSelect, showScenarioSelect] = useState(false);

  useEffect(() => {
    setProjectTotals(ComputeProjectTotals());

    setFutureScenarios(animalAgricultureReport[0]?.scenarios);

    // Update top level if report is complete and new
    if (
      animalAgricultureReport &&
      animalAgricultureReport.length > 0 &&
      reportInfo !== animalAgricultureReport
    ) {
      setReport(animalAgricultureReport);
    }
  }, [animalAgricultureReport]);

  // Graph/Table swap
  const [display, setDisplay] = useState("dual");

  // Display tabs
  function CreateTabs() {
    const reportData =
      reportInfo && reportInfo.length > 0
        ? reportInfo
        : animalAgricultureReport;

    const tempTabs = { TabsList: [] };

    if (reportData && reportData.length > 0) {
      reportData.forEach((group) => {
        tempTabs.TabsList.push({
          Tab: group.name,
          Data: (
            <Grid py={0.5} px={2}>
              <Grid>
                <AnimalAgTable
                  group={group}
                  projectTotals={projectTotals}
                  displayedScenarios={displayedScenarios}
                />
              </Grid>
              <Grid>
                <AnimalAgGraph
                  group={group}
                  displayedScenarios={displayedScenarios}
                />
              </Grid>
            </Grid>
          ),
        });
      });
    }

    if (tempTabs.TabsList.length === 0) {
      const defaultTab = {
        Tab: "Animal Agriculture Report Data",
        Data: (
          <Grid container direction="column" columns={1} alignItems="center">
            Loading...
          </Grid>
        ),
      };
      tempTabs.TabsList.push(defaultTab);
    }

    return tempTabs;
  }
  const [anAgTabs, setTabs] = useState(CreateTabs());
  useEffect(() => {
    setTabs(CreateTabs);
  }, [display, displayedScenarios, reportInfo, animalAgricultureReport]);

  // Demo info popup
  const [demoPopupShown, setDemoPopupShown] = useState(false);
  useEffect(() => {
    if (isDemo(212320, currentUser) && !demoPopupShown) {
      setDemoPopupShown(true);
      updateLocalPopup({
        title: "Animal Agriculture Beef or Dairy Demo Project",
        style: { minWidth: "750px", maxHeight: "75vh", overflow: "auto" },
        children: [
          <Grid container direction="column" alignItems="center" p={2}>
            <CFTypography variant="h4" py={1}>
              Animal Agriculture Report
            </CFTypography>
            <p>
              Values are reported in COMET-Farm as either positive values or
              negative. A positive value indicates that there is an increase in
              emissions. Values that are reported as a negative number indicates
              that there is a reduction in emissions.
            </p>
          </Grid>,
        ],
        showPopup: true,
        closeAction: () => {
          updateLocalPopup({ showPopup: false });
        },
      });
    }
  }, [currentUser.activeProject]);

  return (
    <Grid container direction="column" alignItems="center">
      {/* Title */}
      <Grid container direction="column" alignItems="start" pl={1}>
        <Grid container direction="row" alignItems="center" py={2}>
          <Grid item xs={8}>
            <CFTypography variant="h4">
              Animal Agriculture Report Summary
            </CFTypography>
          </Grid>
          <Grid
            item
            xs={4}
            container
            direction="row"
            justifyContent="end"
            alignItems="center"
          >
            <CFTypography variant="h6" fontWeight="regular" pr={2}>
              Report Display:
            </CFTypography>
            <RadioGroup
              defaultValue="dual"
              row
              onChange={(e) => setDisplay(e.target.value)}
            >
              <FormControlLabel value="dual" control={<Radio />} label="Dual" />
              <FormControlLabel
                value="graph"
                control={<Radio />}
                label="Graph"
              />
            </RadioGroup>
          </Grid>
        </Grid>
        {/* Units message */}
        <Grid item container direction="row" alignItems="center" xs={1} pb={1}>
          {/* Units message */}
          <Grid item xs={10} pl={1}>
            <CFTypography variant="h6" fontWeight="regular" fontStyle="italic">
              All estimates are presented in terms of metric tonnes of CO
              <sub>2</sub>-equivalent over the provided scenario period.
            </CFTypography>
          </Grid>
          {/* Display settings */}
          <Grid
            item
            container
            direction="row"
            xs={2}
            justifyContent="flex-end"
            alignItems="center"
          >
            {/* Show future scenario dialog */}
            {futureScenarios?.length > 0 && (
              <Grid item pr={1}>
                <CFButton
                  variant="gradient"
                  color="info"
                  onClick={() => showScenarioSelect(true)}
                >
                  View Scenarios
                </CFButton>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>

      {display === "dual" ? (
        // Group Card
        <Grid m={1} maxWidth="99%" width="100%">
          <CFTabView verbose>{anAgTabs}</CFTabView>
        </Grid>
      ) : (
        // Graph Selection and Display
        <Grid m={1} maxWidth="99%" width="100%" alignItems="start">
          <AnimalAgProjectGraph
            animalAgData={animalAgricultureReport}
            displayedScenarios={displayedScenarios}
          />
        </Grid>
      )}

      {scenarioSelect && (
        <AnimalAgScenarioSelect
          isOpen={scenarioSelect}
          setIsOpen={showScenarioSelect}
          displayedScenarios={displayedScenarios}
          setDisplayedScenarios={setDisplayedScenarios}
          futureScenarios={futureScenarios}
        />
      )}

      {localPopupState && localPopupState.showPopup && (
        <CFDialog
          {...localPopupState}
          updatePopup={(e) => updateLocalPopup(e)}
        />
      )}
    </Grid>
  );
}
export default AnimalAgReport;
